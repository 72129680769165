.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex-grow: 1;
  justify-content: center;
  padding: 3rem;
  text-align: center;
  border-top: 1px solid var(--border-color);

  p,
  b {
    color: var(--text-color);
    font-size: 0.813rem;
    font-weight: 400;
    line-height: 20px;
  }

  b {
    font-weight: 700;
  }

  p {
    margin-top: 4px;
  }
}

.icon {
  display: block;
  background: url('../assets/comment.svg') no-repeat center;
  width: 36px;
  height: 36px;
}

.showMoreBtn {
  cursor: pointer;
  height: 34px;
  background: var(--color-lightgray);
  color: #333;
  font-size: 0.813rem;
  font-weight: 700;
  border-radius: 24px;
  margin: 16px 0;
  border: none;

  &:disabled {
    cursor: wait;
    color: var(--color-gray);
  }

  &:hover:not(:disabled) {
    opacity: 0.8;
  }
}

.viewsExpressed {
  border-top: 1px solid var(--border-color);
  padding-top: 8px;
  color: var(--color-gray);
  font-size: 0.813rem;
  line-height: 18px;
}
