.container {
  --height: 880px;
  --color-black: #000;
  --color-white: #fff;
  --color-darkgray: #ccc;
  --color-gray: #666;
  --color-lightgray: #e3e3e3;
  --background-color: #fff;
  --border-color: #e3e3e3;
  --path-color: #e3e3e3;
  --voteup-color: #0cac0c;
  --votedown-color: #e01c44;
  --votetext-color: #fff;
  --highlight-base-color: #e6edf7;
  --shadow-base-color: #e6edf7;
  --text-color: #191919;
  --msg-success-color: #eaf7e8;
  --msg-error-color: #f9e9ec;

  position: relative;
  background: var(--background-color);
  max-height: var(--height);
  display: flex;
  flex-direction: column;
}

.commentHeader {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 14px 0 8px;

  h3 {
    background: none !important;
    color: #191919 !important;
    font-size: 1.375rem !important;
    font-weight: 700 !important;
    line-height: 28px;
    letter-spacing: -0.44px;
    margin-bottom: 10px !important;
    padding: 0;
  }

  .commentArea {
    position: relative;
  }

  .articleEnded {
    color: var(--text-color);
    font-size: 1rem;
    line-height: 18px;
    margin-bottom: 8px;
  }

  .commentTabs {
    display: flex;
    gap: 0.25rem;
    padding: 8px 0 0;
    margin: 0;
  }
}

.commentList {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin: 0 -1rem;

  > * {
    flex-shrink: 0;
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

.tncs {
  display: flex;
  justify-content: flex-end;
  color: var(--channel-color-home);
  font-size: 0.813rem;
  margin: 16px 1rem;

  a:hover {
    opacity: 0.8;
  }
}

.paginator {
  padding: 8px 0 0;

  &.hide {
    padding: 0;
  }
}
